<template>
  <GrModal
    name="importar-dados"
    id="importar-dados"
    idModal="importar-dados"
    ref="importar-dados"
    title="Importação"
    @shown="openModal"
    @hidden="$emit('reset-modal')"
    hideFooter
    :hideHeader="hideHeader"
    :size="size"
  >
    <div class="table-body-div" v-if="!loading">
      <div v-if="!importacao || importacao.status != 'done'" class="container-modal " >
        <Step1 
          v-if="steps == 1" 
          @cancel="cancel" 
          @submit="goToStep2"
        />
        <Step2 
        v-if="steps == 2" 
          :file="file" 
          :lista="name" 
          @cancel="cancel" 
          @submit="goToStep3" 
        />
        <Step3 
          v-if="steps == 3"  
          :importacao="importacao" 
          :importData="importData" 
          @submit="goToStep4" 
          @delete="deleteImport" 
        />
        <Step4 
          v-if="steps == 4"
          :sample="sample" 
          :selects="selects" 
          :importacao="importacao" 
          :importData="importData" 
          @step3="steps = 3" 
          @submit="goToStep5" 
          @cancel="cancel"
        />
        <Step5 
          v-if="steps == 5" 
          :importcao="importacao" 
          @cancel="cancel"
        />
      </div>
      <Done 
        v-if="importacao && importacao.status == 'done'" 
        :importacao='importacao'
        @reset="resetModal('importar-dados')"
      />
    </div>
    <div class="d-flex justify-content-center" v-if="loading" style="display: flex; align-items: center; justify-content: center; min-height: 500px" >
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </GrModal>
</template>
<script>
export default {
  components: {
    Step1: () => import('@/components/ImportacaoLeads/ModalSteps/Step1'), 
    Step2: () => import('@/components/ImportacaoLeads/ModalSteps/Step2'), 
    Step3: () => import('@/components/ImportacaoLeads/ModalSteps/Step3'), 
    Step4: () => import('@/components/ImportacaoLeads/ModalSteps/Step4'), 
    Step5: () => import('@/components/ImportacaoLeads/ModalSteps/Step5'), 
    Done: () => import('@/components/ImportacaoLeads/ModalSteps/ModalDone'), 
  },
  data() {
    return {
      steps: 1,
      loading: false,
      lista: null,
      sample: [],
      leads: null,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
      },
      itemSelected: "circle",
      filterStatus: "Todos os status",
      status: "",
      file: null,
      name: "",
      importData: null,
    };
  },
  props: ["importacao"],
  computed: {
    verificUserLevel() {
      var user = this.$store.getters.user.user.level;
      if (user === "owner" || user === "admin" || user == "gerent_seller") {
        return true;
      } else {
        return false;
      }
    },
    size(){
      if (this.importacao?.status == 'done') {
        return 'xl'
      }
      return this.steps == 5 ? 'md' : 'lg'
    },
    hideHeader(){
      return this.steps == 5
    },
  },
  methods: {
    goToStep2(data){
      this.file = data.file
      this.name = data.name
      this.steps = 2
    },
    goToStep3(data){
      this.$emit("export-leads");
      this.steps = 3
      this.importData = data
    },
    goToStep4(data){
      this.sample = data.sample
      this.selects = data.selects
      this.steps = 4
    },
    goToStep5(){
      this.$emit("importar-dados");
      this.steps = 5
    },
    cancel(){
      this.$grModal.hide('importar-dados', this);
    },
    deleteImport(){
      this.$emit("importar-dados");
      this.$grModal.hide("importar-dados", this);
    },
    canShowOption() {
      let level = this.$store.getters.user.user.level;
      if (level != "blogger" && level != "seller" && level != "templater") {
        return true;
      }
      return false;
    },
    openModal() {
      if (!this.importacao) {
        this.steps = 1
      }
      else if (this.importacao && this.importacao.status == "done") {
        this.steps = null;
      } else {
        this.steps = 3;
      }
    },
  },

};
</script>

<style scoped lang="scss">
.container-modal{
  display: grid;
  grid-template-columns: 12fr;
  padding: 20px 0;
}
.table-body-div{
  max-height: 800px !important;
  width: 100% !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
</style>
